import { createBrowserRouter } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ErrorRoutes from './ErrorRoutes';
import AuthLayout from 'layout/AuthLayout/AuthLayout';

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
  [
    {
      element: <AuthLayout />,
      children: [AuthenticationRoutes, MainRoutes, LoginRoutes]
    }
  ],
  {
    basename: process.env.REACT_APP_BASE_NAME
  }
);
export default router;
