// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBooks, IconBrandChrome } from '@tabler/icons-react';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
  IconBrandChrome,
  IconBooks
};

const portfolio: NavItemType = {
  id: 'portfolio',
  title: <FormattedMessage id="portfolio" />,
  caption: <FormattedMessage id="portfolio-caption" />,
  type: 'group',
  children: [
    {
      id: 'study-quiz',
      title: <FormattedMessage id="project-study-quiz" />,
      type: 'collapse',
      icon: icons.IconBooks,
      // url: '/study',
      children: [
        {
          id: 'study-quiz-home',
          title: <FormattedMessage id="study-quiz-home" />,
          type: 'item',
          url: '/study',
          target: false
        },
        {
          id: 'study-quiz-create',
          title: <FormattedMessage id="study-quiz-create" />,
          type: 'item',
          url: '/study/create',
          target: false
        },
        {
          id: 'study-quiz-revise',
          title: <FormattedMessage id="study-quiz-revise" />,
          type: 'item',
          url: '/study/revise',
          target: true
        }
      ]
    }
  ]
};

export default portfolio;
