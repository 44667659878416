// action - state management
import { REDIRECT_START, LOGIN, LOGOUT, REGISTER, REDIRECT_END } from './actions';
import { InitialLoginContextProps } from 'types/auth';

// ==============================|| ACCOUNT REDUCER ||============================== //

interface AccountReducerActionProps {
  type: string;
  payload?: InitialLoginContextProps;
}

const initialState: InitialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false
};

// eslint-disable-next-line
const accountReducer = (state = initialState, action: AccountReducerActionProps) => {
  switch (action.type) {
    case REGISTER: {
      return {
        ...state
      };
    }
    case LOGIN: {
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false
      };
    }
    case REDIRECT_START: {
      return {
        isInitialized: false
      };
    }
    case REDIRECT_END: {
      return {
        isInitialized: true
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
