import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Study from 'views/study';
import { RouteObject } from 'react-router-dom';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

const StudyCreate = Loadable(lazy(() => import('views/study/create')));

const Profile = Loadable(lazy(() => import('views/user/account-profile/Profile1')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: RouteObject = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  errorElement: <h1>ERROR</h1>,
  children: [
    {
      path: '/dashboard',
      element: <Dashboard />
    },
    {
      path: '/study',
      element: <Study />
    },
    {
      path: '/study/create',
      element: <StudyCreate />
    },
    {
      path: '/profile',
      element: <Profile />
    }
  ]
};

export default MainRoutes;
