import { createContext, useEffect, useReducer } from 'react';

// third-party
import { AccountInfo, Configuration, EventType, LogLevel, RedirectRequest } from '@azure/msal-browser';

// reducer - state management
import accountReducer from 'store/accountReducer';
import { InitialLoginContextProps, MsalContextType } from 'types/auth';
import { REDIRECT_START, LOGIN, LOGOUT, REDIRECT_END } from 'store/actions';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import Loader from 'ui-component/Loader';

const REACT_APP_CLIENT_ID = '8db7dc71-1e29-4584-9311-30c65e5cf521';
const REACT_APP_REDIRECT_URI = '/';
const REACT_APP_BC2_SUSI_NAME = 'B2C_1_signupsignin1';
const REACT_APP_BC2_SUSI_AUTH = 'https://perryrose.b2clogin.com/perryrose.onmicrosoft.com/b2c_1_signupsignin1';
const REACT_APP_BC2_AUTH_DOMAIN = 'perryrose.b2clogin.com';

const b2cPolicies = {
  names: {
    signUpSignIn: REACT_APP_BC2_SUSI_NAME
  },
  authorities: {
    signUpSignIn: {
      authority: REACT_APP_BC2_SUSI_AUTH
    }
  },
  authorityDomain: REACT_APP_BC2_AUTH_DOMAIN
};

export const msalConfig: Configuration = {
  auth: {
    clientId: REACT_APP_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const authRequest: RedirectRequest = {
  scopes: ['openid', 'profile']
};

const initialState: InitialLoginContextProps = {
  isInitialized: false
  // user: null
};

// ==============================|| MSAL ||============================== //

const MsalContext = createContext<MsalContextType | null>(null);

export const MsalProviderWrapper = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const user = instance.getActiveAccount();

  useEffect(() => {
    let callbackId: string | null;

    const init = async () => {
      try {
        defaultToFirstAccountIfNoActiveAccount();
        callbackId = instance.addEventCallback(listenForLoginSuccessAndSetAccount());
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  const defaultToFirstAccountIfNoActiveAccount = () => {
    console.log('Trying to default to first account if none active');
    console.log('Active account:');
    console.log(instance.getActiveAccount());
    console.log('All accounts:');
    console.log(instance.getAllAccounts());

    const activeAccount = instance.getActiveAccount();

    if (activeAccount) {
      dispatch({
        type: LOGIN
      });
    } else {
      const allAccounts = instance.getAllAccounts();

      if (allAccounts.length) {
        // Default to first account
        const firstAccount = allAccounts[0];
        instance.setActiveAccount(firstAccount);

        dispatch({
          type: LOGIN
        });
      } else {
        dispatch({
          type: LOGOUT
        });
      }
    }
  };

  const listenForLoginSuccessAndSetAccount = () => {
    return (event: any) => {
      console.log('[EVENT]:', event.eventType);

      if (event.eventType === EventType.HANDLE_REDIRECT_START) {
        console.log('Redirection has started... Showing loading screen');
        dispatch({ type: REDIRECT_START });
      }

      if (event.eventType === EventType.HANDLE_REDIRECT_END) {
        console.log('Redirection has ended... Hiding loading screen');
        dispatch({ type: REDIRECT_END });
      }

      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
      ) {
        console.log("We've received a login event");

        const account = event.payload.account;
        instance.setActiveAccount(account);

        console.log('Account:');
        console.log(account);

        dispatch({
          type: LOGIN
        });
      }
    };
  };

  const login = () => {
    console.log('[MSAL CONTEXT] Logging in!');
    instance.loginRedirect();
  };

  const logout = () => {
    console.log('[MSAL CONTEXT] Logging out!');
    instance.logoutRedirect();
  };

  const register = () => {
    throw new Error('Register not implemented yet');
  };

  const resetPassword = () => {
    throw new Error('Reset password not implemented yet');
  };

  const isLoggedIn = () => {
    return isAuthenticated;
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <MsalContext.Provider value={{ ...state, user, isLoggedIn, login, logout, register, resetPassword }}>{children}</MsalContext.Provider>
  );
};

export default MsalContext;
