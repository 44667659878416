import { Outlet } from 'react-router-dom';

const AuthLayout = () => {
  return (
    // <SWAProvider>
    <Outlet />
    // </SWAProvider>
  );
};

export default AuthLayout;
