import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { LOGIN_PATH } from 'config';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GuardProps } from 'types';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  // const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { user } = useAuth();
  // const { instance } = useMsal();

  useEffect(() => {
    // const user = instance.getActiveAccount();
    if (!isAuthenticated) {
      console.log("User isn't authenticated. Redirecting...");
      console.log('User:');
      console.log(user);
      navigate('/', { replace: true });
    } else {
      console.log('User is authenticated.');
      console.log('User:');
      console.log(user);
    }
  }, [isAuthenticated, navigate, user]);

  return children;
};

export default AuthGuard;
