import { RouterProvider } from 'react-router-dom';

// routing
import router from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import Notistack from 'ui-component/third-party/Notistack';

// auth provider
import { MsalProviderWrapper as AuthProvider, msalConfig } from 'contexts/MsalContext';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

// ==============================|| APP ||============================== //

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

const App = () => (
  <ThemeCustomization>
    {/* RTL layout */}
    {/* <RTLLayout> */}
    <Locales>
      <NavigationScroll>
        <MsalProvider instance={msalInstance}>
          <AuthProvider>
            <>
              <Notistack>
                <RouterProvider router={router} />
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </MsalProvider>
      </NavigationScroll>
    </Locales>
    {/* </RTLLayout> */}
  </ThemeCustomization>
);

export default App;
