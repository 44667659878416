import { Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';

const Study = () => {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <MainCard title="Dashboard">
          <Typography variant="body2">Study</Typography>
        </MainCard>
      </Grid>
      <Grid item xs={12}>
        <SubCard title="Dashboard">
          <Typography variant="body2">Study</Typography>
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default Study;
