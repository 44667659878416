import LAYOUT_CONST from 'constant';

// types
import { ConfigProps } from 'types/config';

export const DASHBOARD_PATH = '/dashboard';
export const LOGIN_PATH = '/login';
export const HORIZONTAL_MAX_ITEM = 7;
export const CONFIG_STORAGE_NAME = 'perryrose-config';

const config: ConfigProps = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: 'light', // light, dark
  presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false
};

export default config;
