import { Avatar, Box, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { LightMode, DarkMode } from '@mui/icons-material';
import useConfig from 'hooks/useConfig';

const ToggleMode = () => {
  const theme = useTheme();
  const { navType, onChangeMenuType } = useConfig();

  const toggleMode = () => {
    onChangeMenuType(navType === 'light' ? 'dark' : 'light');
  };

  return (
    <Box
      sx={{
        ml: 2,
        mr: 2
      }}
    >
      <Tooltip title={navType === 'light' ? 'Toggle dark mode' : 'Toggle light mode'}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            border: '1px solid',
            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
            color: theme.palette.primary.dark,
            transition: 'all .2s ease-in-out',
            '&[aria-controls="menu-list-grow"],&:hover': {
              borderColor: theme.palette.primary.main,
              background: theme.palette.primary.main,
              color: theme.palette.primary.light
            }
          }}
          aria-haspopup="true"
          onClick={toggleMode}
          color="inherit"
        >
          {navType === 'light' ? <LightMode /> : <DarkMode />}
        </Avatar>
      </Tooltip>
    </Box>
  );
};

export default ToggleMode;
